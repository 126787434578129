<template>
  <div>
    <dt>{{ news.title }}</dt>
    <dd class="update">
      {{ formatDate(news.display_date) }}
    </dd>
    <dd class="text">
      <p v-html="news.description" />
    </dd>
  </div>
</template>

<script>
import { commonDate } from '@/helpers/date-format.js'

export default {
  name: 'NewsCard',
  props: {
    news: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatDate (date) {
      return commonDate(date, 'YYYY.MM.DD')
    }
  }
}
</script>

<style scoped></style>
