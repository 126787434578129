<template>
  <div>
    <div id="pageNews" class="mainContents">
      <section class="titleArea">
        <h2>お知らせ</h2>
        <!-- /.titleArea -->
      </section>

      <div class="newsArea">
        <div class="inner">
          <dl>
            <NewsCard
              v-for="news in news.newsList"
              :key="news.node.id"
              :news="news.node"
            />
          </dl>
          <div v-if="news.pageInfo.hasNextPage" class="buttonArea">
            <p>
              <button type="submit" @click="fetchMoreNewsList($event)">
                もっと見る
              </button>
            </p>
            <!-- /.buttonArea -->
          </div>
        </div>
        <!-- /.newsArea -->
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from '@/components/NewsCard.vue'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageNewsList (routeTo, next) {
  const args = { first: 12 }
  store
    .dispatch('news/fetchNewsList', args)
    .then(() => {
      next()
    })
    .catch(() => {
      next({ name: 'network-issue' })
    })
}

export default {
  components: {
    NewsCard
  },
  data () {
    return {
      newsList: [],
      tag: null
    }
  },
  beforeRouteEnter (routeTo, routeFrom, next) {
    getPageNewsList(routeTo, next)
  },
  beforeRouteUpdate (routeTo, routeFrom, next) {
    getPageNewsList(routeTo, next)
  },
  computed: {
    ...mapState(['news'])
  },
  created: function () {
    if (this.$store.getters.loggedIn) {
      store.dispatch('updateLastNewsViewed').then(() => {
        store.dispatch('setUnreadNewsCount', 0)
      })
    }
  },
  methods: {
    fetchMoreNewsList (event) {
      this.$store
        .dispatch('news/fetchNewsList', {
          first: 12,
          after: this.news.pageInfo.endCursor,
          add: true
        })
        .then(() => {})
    }
  }
}
</script>

<style scoped></style>
